import request from '@/utils/request'

export function manageList(query) {
  return request({
    url: '/admin/plan/index',
    method: 'post',
    data: query
  })
}


export function planOperate(query) {
  return request({
    url: '/admin/plan/plan_operate',
    method: 'post',
    data: query
  })
}
