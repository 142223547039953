<template>
  <div class="app-container">
    <div class="label_database" v-loading="labelDataLoding">
      <div class="label_database_title">标签库</div>
      <!-- <div class="label_database_item"><span>默认标签库 (0)</span></div> -->
      <div class="label_database_title_label">
        <div v-for="(item, index) in warehouse_list" @click="clickLabelData(item.id, index)" :key="item.id"
          :class="currentIndex === index ? 'label_database_item select_label_database_item' : 'label_database_item'">
          <span class="label_database_item_name">{{ item.name }} </span>
          <span class="label_database_item_num">({{ item.count }})</span>
          <div class="label_database_item_icon">
            <el-button @click="$refs.addDataBaseLabel.handleUpdate(item)" type="text"><i
                class="el-icon-edit"></i></el-button>
            <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" icon="el-icon-info" icon-color="red"
              title="是否确定删除该标签库吗？" @confirm="handleDeleteLabelDatabase(item.id)">
              <el-button slot="reference" type="text"><i style="color: #F56C6C;" class="el-icon-delete"></i></el-button>
            </el-popconfirm>
          </div>
        </div>
      </div>


      <el-button class="label_database_create_btn" @click="$refs.addDataBaseLabel.handleCreateLabel()" type="primary"
        plain>新建标签库</el-button>
    </div>

    <div class="label_table">
      <!-- 搜索 -->
      <div class="filter-container">

        <el-form :inline="true" :model="listQuery" class="form-inline">
          <div>
            <el-form-item label="">
              <el-input v-model="listQuery.name" placeholder="搜索标签名称" clearable size="small" />
            </el-form-item>
          </div>

          <div>
            <el-form-item>
              <el-button plain v-waves icon="el-icon-refresh" size="small" @click="handleFilterClear">重置</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-waves type="primary" icon="el-icon-search" size="small" @click="handleFilter">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-waves type="primary" icon="el-icon-plus" size="small"
                @click="$refs.labelForm.handleCreate(group_id)">新建标签</el-button>
            </el-form-item>
          </div>

        </el-form>
      </div>
      <!-- 表格 -->
      <el-table :key="tableKey" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column label="标签名称" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="使用次数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.use_count }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.create_name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="$refs.labelForm.handleUpdate(scope.row)">编辑</el-button>
            <el-popconfirm confirm-button-text="确定" style="margin-left: 10px;" cancel-button-text="取消" icon="el-icon-info"
              icon-color="red" title="是否确定删除该标签吗？" @confirm="handleDeleteData(scope.row.id)">
              <el-button slot="reference" type="text"><span style="color: #F56C6C;">删除</span> </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination v-show="total > 0" :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50]"
          :page-size="listQuery.psize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>

    <addForm @requestLabelList="requestLabelList" ref="labelForm"></addForm>
    <addDataBaseLabel @requestLabelGroupsIndex="requestLabelGroupsIndex" ref="addDataBaseLabel"></addDataBaseLabel>
  </div>
</template>
  
<script>
import { labelList, labelGroupsIndex, labelGroupsAdd, labelAdd } from "@/api/label";
import waves from "@/directive/waves";
import addForm from "./components/addForm.vue"
import addDataBaseLabel from "./components/addDataBaseLabel.vue"
export default {
  name: "Log",
  components: { addForm, addDataBaseLabel },
  directives: {
    waves,
  },

  data() {
    return {
      tableKey: 0,
      list: null,
      total: null,
      listLoading: true,
      labelDataLoding: true,
      listQuery: {
        page: 1,
        psize: 10,
        name: "",
        group_id: 0,
      },
      group_id: 0,
      warehouse_list: null,
      currentIndex: 0,
    };
  },
  watch: {
    group_id: function (val) {
      this.listQuery.group_id = val;
      this.requestLabelList();
    }
  },
  created() {
    this.requestLabelGroupsIndex()
  },
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.requestLabelList();
    },
    handleFilterClear() {
      this.listQuery = {
        page: 1,
        psize: 10,
        name: "",
        group_id: this.group_id
      };
      this.requestLabelList();
    },
    handleSizeChange(val) {
      this.listQuery.psize = val;
      this.requestLabelList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.requestLabelList();
    },
    requestLabelList() {
      this.listLoading = true;
      labelList(this.listQuery).then((response) => {
        this.list = response.data.list;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    //删除标签数据
    handleDeleteData(id) {
      let requestData = { id, is_del: 1 }
      labelAdd(requestData)
        .then(res => {
          if (res.status === 1) {
            this.$message.success("删除成功！");
            this.requestLabelList();
          } else {
            this.$message.error("删除失败！");
          }
        })
    },
    // 删除标签库数据
    handleDeleteLabelDatabase(id) {
      let requestData = { id, is_del: 1 }
      labelGroupsAdd(requestData)
        .then(res => {
          if (res.status === 1) {
            this.$message.success(res.msg);
            this.requestLabelGroupsIndex();
          } else {
            this.$message.error(res.msg);
          }
        })
    },
    //获取标签库列表
    requestLabelGroupsIndex() {
      this.labelDataLoding = true
      labelGroupsIndex()
        .then(res => {
          this.warehouse_list = res.data.list
          if (this.warehouse_list.length && this.group_id === 0) {
            this.group_id = this.warehouse_list[0].id;
          }
          this.labelDataLoding = false
        })
    },
    //创建标签库
    hanldOpenCreateLablDataBase() {
      this.$refs.addDataBaseLabel.handleCreateLabel()
    },
    //点击标签库
    clickLabelData(group_id, index) {
      this.group_id = group_id;
      this.currentIndex = index
    }
  },
};
</script>
  

<style lang="scss" scoped>
.app-container {
  display: flex;
  align-items: stretch;

  .label_database {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
    padding-right: 14px;
    margin-right: 14px;
    border-right: 1px solid #dfe6ec;
    font-size: 14px;
    // overflow: auto;

    .label_database_title {
      border-bottom: 1px solid #dfe6ec;
      font-weight: bold;
      padding-bottom: 6px;
      font-size: 18px;
    }

    .select_label_database_item {
      color: #1890ff;
    }

    .label_database_item {
      display: flex;
      align-items: center;
      gap: 6px;
      .label_database_item_name {
        flex: 7;
        cursor: pointer;
        word-break: break-all;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .label_database_item_num{
        flex: 1;
      }
      .label_database_item_icon {
        flex: 2;
        display: flex;
        align-items: center;
        gap: 10px;

      }
    }

    .label_database_create_btn {
      margin-top: auto;
    }
  }

  .label_table {
    flex: 7;
  }

  .form-inline {
    display: flex;
    justify-content: space-between;
  }

  .label_database_title_label {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }


}
</style>
