<template>
    <el-drawer ref="drawer" :with-header="false" size="30%" :before-close="handleClose" :visible.sync="dialogFormVisible"
        direction="rtl" custom-class="demo-drawer">
        <div class="demo-drawer__content">
            <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px"
                style="width: 100%; padding: 10px; height: 100vh; overflow-y: scroll">
                <el-tabs tab-position="top" style="height: 200px">
                    <el-tab-pane tab-index="-1" label="基本信息">
                        <el-form-item label="用户名称" prop="username">
                            <el-input v-model="temp.username" clearable placeholder="账号必填" />
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="temp.password" clearable placeholder="不修改则留空" />
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="temp.email" clearable placeholder="邮箱必填" />
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-switch v-model="temp.is_enabled" active-color="#13ce66" inactive-color="#ff4949"
                                :active-value="1" :inactive-value="0" />
                        </el-form-item>
                        <el-form-item label="负责租户" prop="company_id">
                            <el-select multiple collapse-tags v-model="temp.company_id" placeholder="负责租户">
                                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="租户所在地" prop="county">
                            <el-select v-model="temp.county" placeholder="租户所在地">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item> -->
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div class="demo-drawer__footer" style="position: fixed; top: 15px; right: 30px">
                <el-button size="mini" @click="$refs.drawer.closeDrawer()">取 消</el-button>
                <el-button size="mini" :loading="btnLoading" type="primary" @click="saveData()">
                    保存
                </el-button>
            </div>
        </div>
    </el-drawer>
</template>
  
<script>
import { accountAdd } from "@/api/user";
import {
    validUsername,
    validEmail,
    validPassword,
} from "@/utils/validate";
var checkUsername = (rule, value, callback) => {
    if (validUsername(value)) {
        callback();
    } else {
        return callback(new Error());
    }
};
var checkEmail = (rule, value, callback) => {
    if (!value || validEmail(value)) {
        callback();
    } else {
        return callback(new Error());
    }
};
var checkPassword = (rule, value, callback) => {
    if (!value || validPassword(value)) {
        callback()
    } else {
        return callback(new Error())
    }
};
export default {
    name: "agentForm",
    components: {},
    props: ["options"],
    data() {

        return {
            btnLoading: false,
            temp: {
                id: 0,
                username: "",
                password: "",
                email: "",
                is_enabled: 1,
                company_id: [],
            },
            is_enabled: 1,
            dialogFormVisible: false,
            rules: {},
        };
    },
    watch: {
        dialogFormVisible: {
            handler() {
                this.resetTemp();
            },
            deep: true,
            sync: true,
        }
    },
    created() {

    },
    destroyed() { },
    methods: {
        handleClose(done) {
            if (this.btnLoading) {
                return;
            }
            this.$confirm("更改将不会被保存，确定要取消吗？")
                .then((_) => {
                    this.dialogFormVisible = false;
                })
                .catch((_) => { });
        },

        resetTemp() {
            this.temp = {
                id: 0,
                username: "",
                password: "",
                email: "",
                is_enabled: 1,
                company_id: [],
            };
        },
        handleCreate() {
            this.btnLoading = false;
            this.dialogFormVisible = true;
            this.currentIndex = -1;
            this.rules = {
                username: [
                    { required: true, message: "账号必填", trigger: "blur" },
                    {
                        validator: checkUsername,
                        message: "账号格式是字母，数字，下划线，长度6-20位",
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: "密码必填", trigger: "blur" },
                    { validator: checkPassword, message: '密码格式是字母，数字，下划线，长度6-18位', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: "邮箱必填", trigger: "blur" },
                    { validator: checkEmail, message: "邮箱格式错误", trigger: "blur" },
                ],
                company_id: [
                    { required: true, message: "负责租户必选", trigger: "select" },
                ],
            }
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        handleUpdate(data) {
            this.btnLoading = false;
            this.dialogFormVisible = true;
            this.temp.id = data.id;
            this.temp.username = data.username;
            this.temp.password = "";
            this.temp.email = data.email;
            this.temp.is_enabled = data.is_enabled;
            this.temp.company_id = data.company_list.map(res => {
                return res.id;
            });
            this.rules = {
                username: [
                    { required: true, message: "账号必填", trigger: "blur" },
                    {
                        validator: checkUsername,
                        message: "账号格式是字母，数字，下划线，长度6-18位",
                        trigger: "blur",
                    },
                ],
                email: [
                    { required: true, message: "邮箱必填", trigger: "blur" },
                    { validator: checkEmail, message: "邮箱格式错误", trigger: "blur" },
                ],
                company_id: [
                    { required: true, message: "负责租户必选", trigger: "select" },
                ],
            }
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        saveData() {
            this.btnLoading = true;
            this.$refs["dataForm"].validate((valid) => {
                if (valid) {
                    const _this = this;
                    let request_data = { ..._this.temp };
                    request_data.company_id = request_data.company_id.toString()
                    accountAdd(request_data)
                        .then((response) => {
                            if (response.status === 1) {
                                if (!_this.temp.id) {
                                    _this.temp.id = response.data.id;
                                }
                                this.$emit("requestAgentList");
                                _this.dialogFormVisible = false;
                                _this.$message.success(response.msg);
                            }
                            _this.btnLoading = false;
                        })
                        .catch((error) => {
                            this.btnLoading = false;
                        });
                } else {
                    this.btnLoading = false;
                }
            });
        },
    },
};
</script>

  