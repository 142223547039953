<template>
  <div class="app-container">
    <!-- 快捷试图 -->
    <div class="scrollview">
      <div class="scrollview_one">
        快捷视图
      </div>
      <div class="scrollview_two">
        <el-tag class="scrollview_two_tag" size="small" @click="handleChangeSearch(item.data)" :key="item.id"
          v-for="(item, index) in viewport" closable :disable-transitions="false" @close="handleClose(index, item.id)">
          {{ item.name }}
        </el-tag>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="filter-container">
      <el-form :inline="true" :model="listQuery" class="form-inline yq_search">
        <div>
          <el-form-item style="width: 220px;" label="">
            <el-input size="small" placeholder="请输入内容" v-model="listQuery.keywords" class="input-with-select">
              <el-select size="small" v-model="listQuery.search_type" slot="append" placeholder="请选择">
                <el-option v-for=" item in config_data.searchTypeArr" :key="item.id" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item label="">
            <el-date-picker style="width: 340px;" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" size="small"
              v-model="listQuery.post_create_time" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item style="width: 90px;" label="">
            <el-select size="small" v-model="listQuery.sentiment" placeholder="信息属性">
              <el-option v-for=" item in config_data.sentimentArr" :key="item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select style="width: 160px;" size="small" multiple collapse-tags v-model="listQuery.company_id"
              placeholder="来源租户">
              <el-option v-for="item in config_data.companyArr" :key="item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 160px;" label="">
            <el-select size="small" multiple collapse-tags v-model="listQuery.plan_id" placeholder="来源方案">
              <el-option v-for=" item in config_data.planArr" :key="item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 160px;" label="">
            <el-cascader placeholder="信息标签" :show-all-levels="false" collapse-tags
              :props="{ value: 'id', label: 'name', children: 'child', multiple: true, }" size="small"
              v-model="listQuery.label_id_list" :options="config_data.labelArr"
              @change="searLabelHandleChange"></el-cascader>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <el-button plain v-waves icon="el-icon-refresh" size="small" @click="handleFilterClear">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-waves type="primary" icon="el-icon-search" size="small" @click="handleFilter">查询</el-button>
          </el-form-item>
        </div>

      </el-form>
    </div>
    <!-- 批量操作 -->
    <div class="batch_opration">
      <div>
        <el-button icon="el-icon-circle-close" @click="requestSmallLibInterrelated(2)" type="goon">无关</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button icon="el-icon-circle-check" @click="requestSmallLibInterrelated(1)" type="goon">相关</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button @click="allCopy($event)" icon="el-icon-copy-document" type="goon">一键复制</el-button> <el-divider
          direction="vertical"></el-divider>
        <el-button icon="el-icon-delete" @click="requestSmallLibClean" type="goon"> <span>一键清库</span> </el-button>
      </div>
      <div class="batch_opration_two">
        <div class="batch_opration_two_info">共 <span class="batch_opration_two_info_aKey">{{ total }}</span> 条信息</div>
        <el-button plain type="primary" size="mini" v-show="listQuery.order === 'desc'" @click="handleOrderChange('asc')"
          icon="el-icon-bottom"> 入库降序 </el-button>
        <el-button plain type="primary" size="mini" v-show="listQuery.order === 'asc'" @click="handleOrderChange('desc')"
          icon="el-icon-top">
          入库升序 </el-button>
        <el-button plain type="primary" size="mini" @click="$refs.searchView.handleOpen(listQuery)"> 保存视图 </el-button>
        <el-button plain type="primary" size="mini" @click="requestSmallLibIndex()"> 刷新 </el-button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table @selection-change="handleSelectionChange" :key="tableKey" v-loading="listLoading" :data="list" fit
      highlight-current-row style="width: 100%">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column label="信息内容" align="left">
        <template slot-scope="scope">
          <div class="list_data_item">
            <div class="list_data_item_img" @click="$refs.playVideoView.handleOpen(scope.row)"> <img
                :src="scope.row.online_url" @error="setDefaultImage" alt=""> </div>
            <div class="list_data_item_right">
              <div class="list_data_item_right_one">
                <div class="list_data_item_right_one_title">
                  <el-tooltip class="item" effect="dark" :content="scope.row.title" placement="top">
                    <div class="list_data_item_right_one_title">{{ scope.row.title }}</div>
                  </el-tooltip>
                </div>
                <div class="list_data_item_right_one_select">
                  <el-select
                    :class="scope.row.sentiment === 1 ? 'one_select_blue' : scope.row.sentiment === 2 ? 'one_select_red' : 'one_select_gray'"
                    size="mini" @change="(val) => requestSmallLibSentiment(val, scope.row.post_id)"
                    v-model="scope.row.sentiment" placeholder="信息属性">
                    <el-option v-for=" item in config_data.sentimentArr_1" :key="item.id" :label="item.name"
                      :value="item.id">
                      <span style="float: left;"
                        :class="item.name === '正面' ? 'select_options_blue' : item.name === '负面' ? 'select_options_red' : 'select_options_gray'">{{
                          item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="list_data_item_right_two">
                <div class="list_data_item_right_two_1">精准地域:
                  <el-tag v-if="scope.row.ip_location" size="mini">{{ scope.row.ip_location }}</el-tag>
                  <span v-else>-</span>
                </div>
                <div class="list_data_item_right_two_2">舆情类型:
                  <el-popover v-if="scope.row.label_names.length > 3" placement="right" width="400" trigger="hover">
                    <div class="list_data_item_right_two_2_hover"><el-tag v-for="(item, index) in scope.row.label_names"
                        :key="index" size="mini">{{ item
                        }}</el-tag></div>
                    <div slot="reference"><el-tag v-for="(item, index) in scope.row.label_names.slice(0, 3)" :key="index"
                        size="mini">{{ item }}</el-tag></div>
                  </el-popover>
                  <div v-else-if="scope.row.label_names.length < 4 && scope.row.label_names.length > 0"><el-tag
                      v-for="(item, index) in scope.row.label_names" :key="index" size="mini">{{ item }}</el-tag></div>
                  <span v-else>-</span>
                </div>
                <div class="list_data_item_right_two_3">来源租户:
                  <div v-if="scope.row.company_names.length > 0"><span v-for="(item, index) in scope.row.company_names"
                      :key="index">{{ item }};</span> </div>
                  <span v-else>-</span>
                </div>
                <div class="list_data_item_right_two_4">来源方案:
                  <div v-if="scope.row.plan_names.length > 0"><span v-for="(item, index) in scope.row.plan_names"
                      :key="index"> <el-checkbox v-model="scope.row.plan_names[index][2]"></el-checkbox>{{
                        scope.row.plan_names[index][1] }} </span></div>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="list_data_item_right_three">
                <div class="list_data_item_right_three_1">
                  <div><el-avatar shape="square" :size="20" :src="scope.row.avatar"></el-avatar>
                    <span>{{ scope.row.nickname }}</span>
                  </div>
                  <div> | </div>
                  <div>
                    <i
                      :class="scope.row.platform === 'douyin' ? 'iconfont yq-icon-douyin ' : scope.row.platform === 'weibo' ? 'iconfont yq-icon-weibo' : 'iconfont yq-icon-qita'"></i>发布于
                    {{ scope.row.post_publish_time }}
                  </div>
                </div>
                <div class="list_data_item_right_three_2">
                  <i class="iconfont yq-icon-add_database"></i> 入库时间: {{ scope.row.post_create_time }}
                </div>
                <div class="list_data_item_right_three_3">
                  <span><i class="el-icon-star-off"></i> <span>{{ scope.row.like_count }}</span> </span>
                  <span><i class="el-icon-chat-dot-round"></i> <span>{{ scope.row.comment_count }}</span> </span>
                  <span><i class="el-icon-share"></i> <span>{{ scope.row.share_count }}</span> </span>
                </div>
              </div>
            </div>
          </div>

        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" width="200">
        <template slot-scope="scope">
          <div class="operate">
            <div class="operate_btn">
              <el-button icon="el-icon-bell" type="primary" size="mini"
                @click="$refs.directWarn.handleOpen(scope.row)">预警</el-button>
              <el-button icon="el-icon-circle-check" type="primary" plain size="mini"
                @click="requestSmallLibInterrelatedOne(1, scope.row)">相关</el-button>
            </div>
            <div class="operate_btn">
              <el-button icon="el-icon-edit-outline" size="mini"
                @click="$refs.editView.handleOpen(scope.row)">编辑</el-button>
              <el-button icon="el-icon-circle-close" size="mini"
                @click="requestSmallLibInterrelatedOne(2, scope.row)">无关</el-button>
            </div>
            <div class="operate_btn">
              <el-popover placement="bottom" @show="one_data_select_label_list = scope.row.label_ids"
                @hide="requestSmallLibLabel(scope.row.post_id, scope.row.label_ids)" width="200" trigger="click">
                <el-cascader placeholder="信息标签" :show-all-levels="false" collapse-tags clearable
                  :props="{ value: 'id', label: 'name', children: 'child', multiple: true, checkStrictly: false }"
                  size="small" v-model="scope.row.label_ids" :options="config_data.labelArr"
                  @change="(res) => searLabelHandleChangeOne(res, scope.$index, scope.row)"></el-cascader>
                <el-button icon="el-icon-price-tag" size="mini" slot="reference">标签</el-button>
              </el-popover>
              <el-button icon="el-icon-copy-document" style="margin-left: 10px;" size="mini"
                @click="handleClipboard(oneCopy(scope.row), $event)">复制</el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination v-show="total > 0" :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.psize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <searchView @requestSmallLibViewIndex="requestSmallLibViewIndex" ref="searchView"></searchView>
    <editView ref="editView" :sentimentArr="config_data.sentimentArr"></editView>
    <directWarn ref="directWarn"></directWarn>
    <playVideoView ref="playVideoView"></playVideoView>
  </div>
</template>
      
<script>
import { smallLibConfig, smallLibIndex, smallLibInterrelated, smallLibClean, smallLibViewIndex, smallLibViewDelete, smallLibSentiment, smallLibLabel } from "@/api/audit";

import waves from "@/directive/waves";
import searchView from "./components/searchView.vue"
import editView from "./components/editView.vue"
import directWarn from "./components/directWarn.vue"
import playVideoView from "./components/playVideoView"
import clipboard from '@/utils/clipboard'

export default {
  name: "Log",
  components: { searchView, editView, directWarn, playVideoView },
  directives: {
    waves,
  },
  filters: {},
  data() {
    return {
      tableKey: 0,
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        psize: 10,
        keywords: '',
        search_type: 1,
        post_create_time: '',
        sentiment: '',
        company_id: [],
        plan_id: [],
        label_id: [],
        order: 'desc',
        label_id_list: []
      },
      options: [
        {
          value: 0,
          label: "未开启",
        },
        {
          value: 1,
          label: "已开启",
        },
      ],
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      config_data: {},
      multipleSelection: [],
      viewport: [],
      one_data_select_label_list: [],
    };
  },
  watch: {
  },
  created() {
    this.requestSmallLibViewIndex(); // 快捷视图列表
    this.requestSmallLibConfig(); //获取基本配置信息
    this.requestSmallLibIndex(); //获取列表
  },
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.requestSmallLibIndex();
    },
    handleFilterClear() {
      this.listQuery = {
        page: 1,
        psize: 10,
        keywords: '',
        search_type: 1,
        post_create_time: '',
        sentiment: '',
        company_id: [],
        plan_id: [],
        label_id: [],
        order: 'desc',
        label_id_list: []
      };
      this.requestSmallLibIndex();
    },
    handleSizeChange(val) {
      this.listQuery.psize = val;
      this.requestSmallLibIndex();
    },
    handleOrderChange(val) {
      this.listQuery.order = val;
      this.requestSmallLibIndex();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.requestSmallLibIndex();
    },
    // 请求配置接口
    requestSmallLibConfig() {
      smallLibConfig({})
        .then(res => {
          if (res.status === 1) {
            this.config_data = res.data
          }
        })
    },
    // 请求列表接口
    requestSmallLibIndex() {
      this.listLoading = true;
      smallLibIndex(this.listQuery)
        .then(res => {
          this.list = res.data.list;//数据列表 （列表） （数组） 或 对象（键值对）
          this.total = res.data.total;
          this.listLoading = false;
        })
    },
    // 搜索标签多级选择器
    searLabelHandleChange(value) {
      let select_label = []
      select_label = value.map(res => {
        return res[res.length - 1]
      })
      this.listQuery.label_id_list = value
      this.listQuery.label_id = select_label
    },
    //修改单条数据标签
    searLabelHandleChangeOne(value, index, data) {
      console.log(value)
      // let change_label = []
      // change_label = value.map(res => {
      //   return res[res.length - 1]
      // })
      // this.list[index].label_id = value
    },
    // 全选 选择数据操作
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    // 批量相关 无关
    requestSmallLibInterrelated(interrelated_type) {
      let plan_ids = {};
      this.multipleSelection.map(res => {

        let select_plan_names = res.plan_names.map(res => {
          if (res[2] === true) {
            return res[0]
          }
        }).filter(l => l != undefined)

        if (select_plan_names.length > 0) {
          plan_ids[res.post_id] = select_plan_names
        }

      })

      let requestData = { interrelated_type, plan_ids }
      smallLibInterrelated(requestData)
        .then(res => {
          if (res.status === 1) {
            this.$message.success(res.msg);
            this.requestSmallLibIndex();
          } else {
            this.$message.error(res.msg);
          }
        })
    },
    // 单条相关 无关
    requestSmallLibInterrelatedOne(interrelated_type, data) {
      let plan_id = data.plan_names.map(res => {
        if (res[2] === true) {
          return res[0]
        }
      }).filter(l => l != undefined)
      let requestData = {}
      if (plan_id.length > 0) {
        requestData = { interrelated_type, plan_ids: { [data.post_id]: plan_id } }
        console.log(requestData)
        smallLibInterrelated(requestData)
          .then(res => {
            if (res.status === 1) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          })
      } else {
        requestData = { interrelated_type, plan_ids: {} }
        this.$message.error('必须勾选一条方案!');
      }

    },
    // 一键清库
    requestSmallLibClean() {
      let requestData = { post_ids: this.multipleSelection.map(res => { return res.post_id }) }
      smallLibClean(requestData)
        .then(res => {
          if (res.status === 1) {
            this.$message.success(res.msg);
            this.requestSmallLibIndex();
          } else {
            this.$message.error(res.msg);
          }
        })
    },
    // 视图列表
    requestSmallLibViewIndex() {
      smallLibViewIndex({})
        .then(res => {
          if (res.status === 1) {
            this.viewport = res.data
          }
        })
    },
    // 使用快捷视图
    handleChangeSearch(data) {
      this.listQuery = data;
      this.listQuery.page = 1;
      this.listQuery.psize = 10;
      this.requestSmallLibIndex()
    },
    // 删除试图
    handleClose(index, id) {
      smallLibViewDelete({ view_id: id })
        .then(res => {
          if (res.status === 1) {
            this.$message.success(res.msg);
            this.viewport.splice(this.viewport.indexOf(index), 1);
            this.requestSmallLibViewIndex();
          } else {
            this.$message.error(res.msg);
          }
        })
    },
    // 信息属性修改
    requestSmallLibSentiment(event, post_id) {
      smallLibSentiment({ sentiment: event, post_id })
        .then(res => {
          if (res.status === 1) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
    },
    // 修改单条数据标签
    requestSmallLibLabel(post_id, label_ids) {
      if (this.one_data_select_label_list === label_ids) {
        console.log('数据一致无需请求')
      } else {
        let request_data = { post_id }
        request_data.label_ids = label_ids.map(res => {
          return res[res.length - 1]
        })
        smallLibLabel(request_data)
          .then(res => {
            if (res.status === 1) {
              this.$message.success(res.msg);
              this.requestSmallLibIndex(); //获取列表
            } else {
              this.$message.error(res.msg);
            }
          })
      }

    },
    // 显示默认图
    setDefaultImage(e) {
      e.target.src = require('@/assets/no_video.png')
    },
    // 复制
    handleClipboard(text, event) {
      clipboard(text, event)
    },
    // 复制一个
    oneCopy(data) {
      let str = ''
      str = `
标题: ${data.title} \n
消息类型: ${data.sentimentText} \n
精准地域: ${data.ip_location} \n
舆情类型: ${data.label_names.map(res => { return res + ';' })} \n
来源租户: ${data.company_names.map(res => { return res + ';' })} \n
来源方案: ${data.plan_names.map(res => { return res[1] + ';' })} \n
发布昵称: ${data.nickname} \n
发布时间: ${data.post_publish_time} \n
入库时间: ${data.post_create_time} \n
      `
      return str
    },
    // 复制多个或一组数据到剪贴板中
    allCopy(event) {
      let str = '';
      this.multipleSelection.forEach(res => {
        str += this.oneCopy(res)
      })
      this.handleClipboard(str, event)
    }
  },
};
</script>
<style lang="scss" scoped>
.form-inline {
  display: flex;
  justify-content: space-between;
}

.addTelMan .el-button {
  margin-left: 2px;
  width: 20px;
  height: 20px;
  padding: 0;

  >>>i {
    font-size: 30px;
  }
}

.list_data_item {
  display: flex;
  gap: 4px;

  .list_data_item_img {
    width: 80px;
    min-width: 80px;
    height: 104px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .list_data_item_right {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .list_data_item_right_one {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;

      .list_data_item_right_one_title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .list_data_item_right_one_select {
        width: 80px;
        min-width: 80px;
      }
    }

    .list_data_item_right_two {
      display: flex;
      align-items: center;
      gap: 28px;
      flex: 8;
      white-space: nowrap;

      .list_data_item_right_two_1,
      .list_data_item_right_two_2 {
        display: flex;
        gap: 4px;
        align-items: center;

        div {
          display: flex;
          gap: 4px;
          align-items: center;
        }
      }

      .list_data_item_right_two_4 {
        .el-checkbox {
          margin-right: 2px;
        }
      }
    }

    .list_data_item_right_three {
      display: flex;
      align-items: center;
      gap: 20px;
      white-space: nowrap;

      .list_data_item_right_three_3 {
        gap: 8px;

        span {
          gap: 2px;
        }
      }

      >* {
        display: flex;
        align-items: center;

        >* {
          display: flex;
          align-items: center;
        }
      }

      .list_data_item_right_three_1 {
        gap: 6px;

        :first-child {
          gap: 2px;
        }
      }
    }

  }
}

.operate {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-left: 1px solid #dfe6ec;

  .operate_btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.el-button--goon {
  padding: 10px 6px;
  color: #606266;
  border: none;
  background: none;
}

.el-button--goon:hover {
  color: #8b8f96;
  border: none;
  background: none;
}

.el-button--goon:focus {
  color: #606266;
  border: none;
  background: none;
}

.el-button--goon:active {
  color: #606266;
  border: none;
  background: none;
}

.batch_opration {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .batch_opration_two {
    display: flex;
    align-items: center;
    font-size: 14px;

    .batch_opration_two_info {
      margin-right: 10px;
    }
  }
}

.scrollview {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f0f2f5;
  padding: 8px 4px;
  margin-bottom: 12px;

  .scrollview_one {
    font-size: 14px;
    color: #606266;
    padding-right: 4px;
    border-right: 2px solid #b0b3b9;
  }

  .scrollview_two {
    display: flex;
    align-items: center;
    gap: 6px;

    .scrollview_two_tag {
      cursor: pointer;
    }
  }
}

.filter-container {
  padding-bottom: 0px;
}

.yq_search .el-form-item {
  margin-bottom: 12px;
}

.batch_opration_two_info_aKey {
  color: #1890ff;
}



.select_options_blue {
  color: #1890ff;
}

.select_options_red {
  color: #F56C6C;
}

.select_options_gray {
  color: #606266;
}

.list_data_item_right_two_3,
.list_data_item_right_two_4 {
  display: flex;
  gap: 4px;
}

.list_data_item_right_two_2_hover {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}
</style>


<style scoped>
/deep/.input-with-select {
  margin-top: 2px;
}

/deep/.input-with-select .el-select .el-input {
  width: 100px;
}

/deep/.input-with-select .el-input-group__append {
  margin-top: 2px;
  background: #fff;
}

/deep/.one_select_blue .el-input__inner {
  color: #1890ff;
}

/deep/.one_select_red .el-input__inner {
  color: #F56C6C;
}

/deep/.one_select_gray .el-input__inner {
  color: #606266;
}
</style>