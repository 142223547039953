<template>
    <el-dialog title="播放审核视频" :visible.sync="dialogVisible" width="30%" height="50%" :before-close="handleClose">
        <video-player class="video-player-box" ref="videoPlayer" :options="playerOptions" :playsinline="true"
            customEventName="customstatechangedeventname" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)"
            @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)" @playing="onPlayerPlaying($event)"
            @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
            @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)"
            @statechanged="playerStateChanged($event)" @ready="playerReadied">
        </video-player>
    </el-dialog>
</template>
<!-- 
    // 视频格式
    type："video/webm"   // 可以播放，用ogg也可打开
    type："video/ogg"    // 可以播放，用webm也可打开
    type："video/3gp"    // 可以播放
    type："video/mp4"    // 可以播放
    type："video/avi"    // 打不开 无法播放
    type："video/flv"    // 打不开 可以使用flvjs代替
    type："video/mkv"    // 打不开 使用video/mp4可以播放
    type："video/mov"    // 打不开 使用video/mp4可以播放
    type："video/mpg"    // 打不开 未测试
    type："video/swf"    // 打不开  未测试
    type："video/ts"     // 打不开 未测试
    type："video/wmv"    // 打不开 未测试
    type："video/vob"    // 没转化 未测试
    type："video/mxf"    // 转化出错 未测试
    type: "video/rm"     // 转化出错 未测试
    // 组件方法
    重置进度条
    this.player.src(src)
    加载视频
    this.player.load();
    播放视频
    this.player.play();
    暂停播放
    this.player.pause();
    直接全屏 如果当前设备支持的话
    this.player.requestFullscreen();
    在全屏模式下，将视频恢复到正常大小
    this.player.exitFullscreen();
    当环境不支持全屏时 则扩展铺满容器
    this.player.enterFullWindow();
    重置播放器
    this.player.reset();
    返回当前播放源信息
    this.player.currentSources();
    获取或者设置预加载属性
    this.player.preload(val);
    播放控件是否显示
    this.player.controls(false);
    /* 父组件可以通过获取当前组件的 player 来调用 videoPlayer 组件的方法 */
 -->

<script>
// require styles
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'

export default {
    components: {
        videoPlayer
    },
    data() {
        return {
            // [配置信息](https://blog.csdn.net/qq_39905409/article/details/98481735)
            playerOptions: {
                // videojs options
                autoplay: false,
                muted: false,
                preload: 'auto',
                language: 'en',
                fluid: true,
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                    {
                        type: 'video/mp4',
                        src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm'
                    }
                ],
                poster: 'https://t7.baidu.com/it/u=602106375,407124525&fm=193&f=GIF',
                notSupportedMessage: '此视频暂无法播放，请稍后再试',
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true, // 全屏按钮
                    currentTimeDisplay: true, // 当前时间
                    volumeControl: false, // 声音控制键
                    playToggle: true, // 暂停和播放键
                    progressControl: true // 进度条
                }
            },
            dialogVisible: false,
            src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm'
        }
    },
    // mounted() {
    //     console.log('this is current player instance object', this.player)
    // },
    computed: {
        player() {
            return this.$refs.videoPlayer.player
        }
    },
    methods: {
        // listen event
        onPlayerPlay(player) {
            console.log('player play!', player)
        },
        onPlayerPause(player) {
            console.log('player pause!', player)
        },
        onPlayerEnded(player) {
            console.log('player onPlayerEnded!', player)
        },
        onPlayerWaiting(player) {
            console.log('player onPlayerWaiting!', player)
        },
        onPlayerPlaying(player) {
            console.log('player onPlayerPlaying!', player)
        },
        onPlayerLoadeddata(player) {
            console.log('player onPlayerLoadeddata!', player)
        },
        onPlayerTimeupdate(player) {
            console.log('player onPlayerTimeupdate!', player)
        },
        onPlayerCanplay(player) {
            console.log('player onPlayerCanplay!', player)
        },
        onPlayerCanplaythrough(player) {
            console.log('player onPlayerCanplaythrough!', player)
        },
        // ...player event
        // or listen state event
        playerStateChanged(playerCurrentState) {
            console.log('player current update state', playerCurrentState)
        },
        // player is ready
        playerReadied(player) {
            console.log('the player is readied', player)
            // you can use it to do something...
            // player.[methods]
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.player.load();
                    this.player.pause();
                    done();
                })
                .catch(_ => { });
        },
        handleOpen(data) {
            this.dialogVisible = true;
            console.log(data.play_url)
            this.src = data.play_url
            this.playerOptions.sources = [
                {
                    type: 'video/mp4',
                    src: data.play_url
                }
            ],
            this.playerOptions.poster = data.online_url
        }
    }
}
</script>

<style  scoped>
/deep/ .video-player .vjs-big-play-button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
/deep/  .video-js.vjs-fluid{
    padding: 0;
    height: 400px;

}
</style>