import request from '@/utils/request'


// 配置
export function smallLibConfig(query) {
  return request({
    url: '/admin/small_lib/config',
    method: 'post',
    data: query
  })
}

// 列表
export function smallLibIndex(query) {
  return request({
    url: '/admin/small_lib/index',
    method: 'post',
    data: query
  })
}


// 相关、无关、批量相关、批量无关
export function smallLibInterrelated(query) {
  return request({
    url: '/admin/small_lib/interrelated',
    method: 'post',
    data: query
  })
}

// 一键清库
export function smallLibClean(query) {
  return request({
    url: '/admin/small_lib/clean',
    method: 'post',
    data: query
  })
}

// 所有视图
export function smallLibViewIndex(query) {
  return request({
    url: '/admin/small_lib/view_index',
    method: 'post',
    data: query
  })
}

// 删除视图
export function smallLibViewDelete(query) {
  return request({
    url: '/admin/small_lib/view_delete',
    method: 'post',
    data: query
  })
}

// 创建视图
export function smallLibViewAdd(query) {
  return request({
    url: '/admin/small_lib/view_add',
    method: 'post',
    data: query
  })
}


// 信息属性修改
export function smallLibSentiment(query) {
  return request({
    url: '/admin/small_lib/sentiment',
    method: 'post',
    data: query
  })
}

// 同步互动数据
export function smallLibInteraction(query) {
  return request({
    url: '/admin/small_lib/interaction',
    method: 'post',
    data: query
  })
}

// 编辑
export function smallLibEdit(query) {
  return request({
    url: '/admin/small_lib/edit',
    method: 'post',
    data: query
  })
}


// 直接预警
export function smallLibEarlyWarning(query) {
  return request({
    url: '/admin/small_lib/early_warning',
    method: 'post',
    data: query
  })
}


// 标签修改
export function smallLibLabel(query) {
  return request({
    url: '/admin/small_lib/label',
    method: 'post',
    data: query
  })
}