<template>
    <el-dialog title="标签库" v-loading="dialogLoading" :visible.sync="dialogFormVisible">
        <el-form ref="labelDatabaseForm" :model="form" :rules="rules">
            <el-form-item label="标签库名称" label-width="120px" prop="name">
                <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addLabelDatabase">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { labelGroupsAdd } from "@/api/label";

export default {
    data() {
        return {
            dialogFormVisible: false,
            dialogLoading: false,
            form: {
                id: 0,
                name: '',
            },
            rules: {
                name: [
                    { required: true, message: "标签库名称必填", trigger: "blur" },
                ],
            },
        }
    },
    methods: {
        //创建一个新标签页面的操作
        handleCreateLabel() {
            this.dialogFormVisible = true;
            this.$nextTick(() => {
                this.$refs["labelDatabaseForm"].clearValidate();
            });
        },
        // 添加标签库
        addLabelDatabase() {
            this.dialogLoading = true;
            labelGroupsAdd(this.form)
                .then(res => {
                    console.log(res)
                    if (res.status === 1) {
                        this.dialogLoading = false;
                        this.dialogFormVisible = false;
                        this.clearForm();
                        this.$message.success(res.msg);
                        this.$emit("requestLabelGroupsIndex");
                    }
                })
                .catch(error=>{
                    this.dialogLoading = false;
                })
        },
        // 清除数据的已定义属性值，并重新定义表格数据的属性值
        clearForm() {
            this.form = {
                id: 0,
                name: '',
            }
        },
        //打开更新弹窗
        handleUpdate(data){
            this.form.id = data.id;
            this.form.name = data.name;
            this.dialogFormVisible = true;
            this.$nextTick(() => {
                this.$refs["labelDatabaseForm"].clearValidate();
            });
        }
    }
}
</script>

<style></style>