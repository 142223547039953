<template>
  <div class="app-container">
    <!-- 搜索 -->
    <div class="filter-container">
      <el-form :inline="true" :model="listQuery" class="form-inline">
        <div>
          <el-form-item label="">
            <el-input v-model="listQuery.keywords" placeholder="搜索租户或方案名称" clearable size="small" />
          </el-form-item>
          <el-form-item label="">
            <el-select size="small" multiple v-model="listQuery.smart_push" placeholder="是否开启">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <span class="search_row_content">共 <span class="search_row_content_aKey">{{ total }}</span> 个智推租户 | 已开启 <span
                class="search_row_content_aKey">{{ zt_total }}</span> 个智推方案</span>
          </el-form-item>
          <el-form-item>
            <el-button plain v-waves icon="el-icon-refresh" size="small" @click="handleFilterClear">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-waves type="primary" icon="el-icon-search" size="small" @click="handleFilter">查询</el-button>
          </el-form-item>
        </div>

      </el-form>
    </div>

    <!-- 表格 -->
    <el-table :key="tableKey" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%">
      <el-table-column label="租户名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="方案名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.created_at
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="近24h数据量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.data_count }}</span>
        </template>
      </el-table-column>

      <el-table-column label="邮件接收人" align="center">
        <template slot-scope="scope">
          <span class="addTelMan">{{ scope.row.contact.length }}人
            <el-popover placement="bottom" trigger="click" @show="email_qw_show_data = scope.row.email_is_check_list"
              @hide="requestPlanOperate({ id: scope.row.id, email_receive: scope.row.email_is_check_list.toString() }, email_qw_show_data === scope.row.email_is_check_list ? false : true)">
              <el-select multiple v-model="scope.row.email_is_check_list" placeholder="请选择邮件接收人">
                <el-option v-for="item in scope.row.contact" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
              <el-button slot="reference" class="addTelMan_btn" size="mini" icon="el-icon-plus"></el-button>
            </el-popover>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="企微接收人" align="center">
        <template slot-scope="scope">
          <span class="addTelMan">{{ scope.row.contact.length }}人
            <el-popover placement="bottom" trigger="click" @show="email_qw_show_data = scope.row.wx_is_check_list"
              @hide="requestPlanOperate({ id: scope.row.id, qw_receive: scope.row.wx_is_check_list.toString() }, email_qw_show_data === scope.row.wx_is_check_list ? false : true)">
              <el-select multiple v-model="scope.row.wx_is_check_list" placeholder="请选择企微接收人">
                <el-option v-for="item in scope.row.contact" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
              <el-button class="addTelMan_btn" slot="reference" size="mini" icon="el-icon-plus"></el-button>
            </el-popover>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否开启智推" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.smart_push"
            @change="(value) => requestPlanOperate2({ smart_push: value, id: scope.row.id, }, true)" active-color="#13ce66"
            inactive-color="#ff4949" :active-value="1" :inactive-value="0" />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.addForm.handleUpdate(scope.row)">查看方案</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination v-show="total > 0" :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.psize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <addForm ref="addForm"></addForm>
  </div>
</template>
    
<script>
import { manageList, planOperate } from "@/api/manage";
import waves from "@/directive/waves";
import addForm from "./components/addForm.vue"

export default {
  name: "Log",
  components: { addForm },
  directives: {
    waves,
  },
  filters: {},
  data() {
    return {
      tableKey: 0,
      list: null,
      total: null,
      zt_total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        psize: 10,
        smart_push: [0, 1],
        keywords: "",
      },
      options: [
        {
          value: 0,
          label: "未开启",
        },
        {
          value: 1,
          label: "已开启",
        },
      ],
      email_qw_show_data: [],
    };
  },
  watch: {
  },
  created() {
    this.requestManageList();
  },
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.requestManageList();
    },
    handleFilterClear() {
      this.listQuery = {
        page: 1,
        psize: 10,
        smart_push: [0, 1],
        keywords: "",
      };
      this.requestManageList();
    },
    handleSizeChange(val) {
      this.listQuery.psize = val;
      this.requestManageList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.requestManageList();
    },
    requestManageList() {
      this.listLoading = true;
      let request_data = { ...this.listQuery }
      request_data.smart_push = request_data.smart_push.length === 2 ? '' : request_data.smart_push[0]
      manageList(request_data)
        .then(res => {
          let list = res.data.list
          list.forEach((item, index) => {
            list[index].email_is_check_list = this.checkRecipient(item.contact, 'email_is_check')
            list[index].wx_is_check_list = this.checkRecipient(item.contact, 'wx_is_check')
          });
          this.list = list;
          this.total = res.data.total;
          this.zt_total = res.data.zt_total;
          this.listLoading = false;
        })
    },
    checkRecipient(data, name) {

      let response = data.map(res => {
        if (res[name] === 1) {
          return res.id
        }
      })
      return response.filter(res => {
        return res
      });
    },
    // 修改数据接口
    requestPlanOperate(requestData, is_change) {
      if (is_change) {
        planOperate(requestData)
          .then(res => {
            if (res.status === 1) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          })
      }

    },
    // 修改数据接口2
    requestPlanOperate2(requestData, is_change) {
      if (is_change) {
        planOperate(requestData)
          .then(res => {
            if (res.status === 1) {
              this.$message.success(res.msg);
              this.requestManageList();
            } else {
              this.$message.error(res.msg);
            }
          })
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.search_row_content {
  color: #606266;

  .search_row_content_aKey {
    color: #1890ff;
  }
}

.form-inline {
  display: flex;
  justify-content: space-between;
}

.addTelMan {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .addTelMan_btn {
    padding: 0;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
}


// .addTelMan .el-button {
//   margin-left: 2px;
//   width: 20px;
//   height: 20px;
//   padding: 0;

//   >>>i {
//     font-size: 30px;
//   }
// }
</style>