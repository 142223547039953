import request from '@/utils/request'

// 标签列表接口
export function labelList(data) {
  return request({
    url: '/admin/label/index',
    method: 'post',
    data
  })
}

// 标签库组列表
export function labelGroupsIndex(data) {
  return request({
    url: '/admin/labelGroups/index',
    method: 'post',
    data
  })
}

// 新建/编辑标签库
export function labelGroupsAdd(data) {
  return request({
    url: '/admin/labelGroups/add',
    method: 'post',
    data
  })
}

// 新建/编辑标签
export function labelAdd(data) {
  return request({
    url: '/admin/label/add',
    method: 'post',
    data
  })
}
