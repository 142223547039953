<template>
    <el-drawer ref="drawer" :with-header="false" size="30%" :before-close="handleClose" :visible.sync="dialogFormVisible"
        direction="rtl" custom-class="demo-drawer">
        <div class="demo-drawer__content">
            <el-form ref="dataForm" :model="temp" label-position="right" label-width="100px"
                style="width: 100%; padding: 10px; height: 100vh; overflow-y: scroll">
                <el-tabs tab-position="top" style="height: 200px">
                    <el-tab-pane tab-index="-1" label="方案预览">
                        <h4>地域词</h4>
                        <div class="keywords_item">
                            <span v-for="(item, index) in this.temp.word_region" :key="index">{{ item.name }};</span>
                        </div>
                        <h4>关键词</h4>
                        <div>
                            <div class="keywords_item">主体词: <span v-for="(item, index) in this.temp.word"
                                    :key="index">{{ item }};</span> </div>
                            <div class="keywords_item">事件词: <span v-for="(item, index) in this.temp.word_event"
                                    :key="index">{{ item }};</span></div>
                        </div>
                        <h4 v-show="this.temp.word_combination">自定义组合词</h4>
                        <div class="keywords_item" v-show="this.temp.word_combination">
                            <span>{{ this.temp.word_combination }};</span>
                        </div>
                        <h4>排除词</h4>
                        <div class="keywords_item">
                            <span v-for="(item, index) in this.temp.word_exclude" :key="index">{{ item }};</span>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div class="demo-drawer__footer" style="position: fixed; top: 15px; right: 30px">
                <el-button size="mini" @click="$refs.drawer.closeDrawer()">取 消</el-button>

            </div>

        </div>
    </el-drawer>
</template>
  
<script>
export default {
    name: "agentForm",
    components: {},
    props: [],
    data() {
        return {
            btnLoading: false,
            temp: {
                word: [],
                word_event: [],
                word_exclude: [],
                word_region: [],
            },
            status: 1,
            dialogFormVisible: false,
        };
    },
    watch: {
        // temp: {
        //     handler(newVal, oldVal) { },
        //     immediate: true,
        //     deep: true,
        // },
    },
    created() {
        console.log(this.temp)
    },
    destroyed() { },
    methods: {
        handleClose(done) {
            if (this.btnLoading) {
                return;
            }
            this.$confirm("更改将不会被保存，确定要取消吗？")
                .then((_) => {
                    this.dialogFormVisible = false;
                    this.resetTemp();
                })
                .catch((_) => { });
        },

        resetTemp() {
            this.temp = {
                word: [],
                word_event: [],
                word_exclude: [],
                word_region: [],
            };
        },
        handleUpdate(data) {
            this.dialogFormVisible = true
            this.temp = data;
        },
    },
};
</script>
<style lang="scss" scoped>
.keywords_item {
    line-height: 1.8;
    font-family: Menlo, Monaco, Consolas, Courier, monospace;
    font-size: 12px;
    padding: 18px 24px;
    background-color: #fafafa;
    border: 1px solid #eaeefb;
    margin-bottom: 25px;
    border-radius: 4px;
    -webkit-font-smoothing: auto;
}
</style>