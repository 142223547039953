<template>
    <div>
        <!-- Form -->

        <el-dialog title="保存视图" width="25%" :visible.sync="dialogFormVisible">
            <el-form :rules="rules" ref="searchViewForm" :model="search_data" label-position="right" label-width="100px">
                <el-form-item label="视图名称" prop="view_name">
                    <el-input placeholder="请输入视图名称" v-model="search_data.view_name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" :loading="btnLoading" @click="saveData">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { smallLibViewAdd, } from "@/api/audit";
export default {
    data() {
        return {
            dialogFormVisible: false,
            btnLoading: false,
            search_data: {
                view_name: ''
            },
            rules: {
                view_name: [
                    { required: true, message: "视图名称必填", trigger: "blur" },
                ],
            },
            liquery: {},
        }
    },
    methods: {
        handleClose(done) {
            const _this = this;
            if (_this.btnLoading) {
                return;
            }
            _this.$confirm("更改将不会被保存，确定要取消吗？")
                .then((_) => {
                    _this.dialogFormVisible = false;
                    _this.cleaerData()
                })
                .catch((_) => { });
        },
        cleaerData() {
            this.liquery = {}
            this.search_data = {
                view_name: ''
            }
        },
        handleOpen(data) {
            this.dialogFormVisible = true
            this.liquery = data
            this.$nextTick(() => {
                this.$refs["searchViewForm"].clearValidate();
            });
        },
        saveData() {
            this.btnLoading = true;
            this.$refs["searchViewForm"].validate((valid) => {
                if (valid) {
                    const _this = this;
                    let request_data = { ..._this.search_data, ..._this.liquery };
                    smallLibViewAdd(request_data)
                        .then((response) => {
                            if (response.status === 1) {
                                _this.dialogFormVisible = false;
                                _this.$message.success(response.msg);
                                this.$emit('requestSmallLibViewIndex')
                                _this.cleaerData()
                            }
                            _this.btnLoading = false;
                        })
                        .catch((error) => {
                            this.btnLoading = false;
                        });

                } else {
                    this.btnLoading = false;
                }
            });
        },
    }
}
</script>

<style></style>