<template>
    <div>
        <!-- Form -->

        <el-dialog title="直接预警" width="30%" :visible.sync="dialogFormVisible">
            <el-form :rules="rules" ref="editViewForm" :model="form_data" label-position="right" label-width="100px">
                <el-form-item label="标题" prop="title">
                    <el-input :disabled="true" placeholder="请输入标题"  v-model="form_data.title"></el-input>
                </el-form-item>

                <el-form-item label="舆情类型" prop="label_names">
                    <el-tag v-for="(item, index) in father_data.label_names" :key="index">{{ item }}</el-tag>
                </el-form-item>

                <el-form-item label="精准地域" prop="ip_location">
                    <span>{{ father_data.ip_location }}</span>
                </el-form-item>

                <el-form-item label="互动数据">
                    <div class="interactiveData">
                        <div>
                            <span><i class="el-icon-star-off"></i> <span>{{ father_data.like_count }}</span> </span>
                            <span><i class="el-icon-chat-dot-round"></i> <span>{{ father_data.comment_count }}</span>
                            </span>
                            <span><i class="el-icon-share"></i> <span>{{ father_data.share_count }}</span> </span>
                        </div>
                        <div class="interactiveData_right"><el-button size="mini" @click="requestSmallLibInteraction"
                                icon="el-icon-refresh-right">更新数据</el-button></div>
                    </div>

                </el-form-item>


                <el-form-item label="接收人" prop="recipient">
                    <div class="recipient">
                        <el-select size="mini" multiple @change="() => { form_data.recipient = true }"
                            v-model="form_data.email_arr" placeholder="请选择邮件接收人">
                            <el-option v-for=" item in father_data.email_arr" :key="item.id" :label="item.username"
                                :value="item.id"></el-option>
                        </el-select>

                        <el-select size="mini" multiple @change="() => { recipient = true }" v-model="form_data.wx_arr"
                            placeholder="请选择企微接收人">
                            <el-option v-for=" item in father_data.wx_arr" :key="item.id" :label="item.username"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>

                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" :loading="btnLoading" @click="saveData">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {smallLibEarlyWarning, smallLibInteraction } from "@/api/audit";
export default {
    data() {
        return {
            dialogFormVisible: false,
            btnLoading: false,
            form_data: {
                title: '',
                email_arr: [],
                wx_arr: [],
                label_names: [],
                ip_location: '',
                recipient: null,
            },
            rules: {
                title: [
                    { required: true, message: "标题必填", trigger: "blur" },
                ],
                recipient: [{ required: true, message: "联系人必选", trigger: "select" }],
            },
            father_data: {}
        }
    },
    methods: {
        handleClose(done) {
            if (this.btnLoading) {
                return;
            }
            this.$confirm("更改将不会被保存，确定要取消吗？")
                .then((_) => {
                    this.dialogFormVisible = false;
                    this.cleaerData()
                })
                .catch((_) => { });
        },
        cleaerData() {
            this.form_data = {
                title: '',
                email_arr: [],
                wx_arr: [],
                label_names: [],
                ip_location: '',
                recipient: null,
            }
            this.father_data = {}
        },
        handleOpen(data) {
            this.dialogFormVisible = true
            this.father_data = data
            this.form_data.title = data.title
            this.form_data.label_names = data.label_names
            this.form_data.ip_location = data.ip_location
            this.$nextTick(() => {
                this.$refs["editViewForm"].clearValidate();
            });
        },
        saveData() {
            this.btnLoading = true;
            this.$refs["editViewForm"].validate((valid) => {
                if (valid) {
                    const _this = this;
                    let request_data = _this.form_data;
                    request_data.post_id = _this.father_data.post_id
                    smallLibEarlyWarning(request_data)
                        .then((response) => {
                            if (response.status === 1) {
                                _this.dialogFormVisible = false;
                                _this.cleaerData();
                                _this.$message.success(response.msg);
                            }
                            _this.btnLoading = false;
                        })
                        .catch((error) => {
                            this.btnLoading = false;
                        });
                } else {
                    this.btnLoading = false;
                }
            });
        },
        // 同步互动数据
        requestSmallLibInteraction() {
            smallLibInteraction({ post_id: this.father_data.post_id })
                .then(res => {
                    if (res.status === 1) {
                        this.father_data = { ...this.father_data, ...res.data }
                        this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.recipient {
    display: flex;
    gap: 14px;
}

.interactiveData {
    display: flex;
    align-items: center;
    .interactiveData_right{
        margin-left: auto;
    }
}
</style>