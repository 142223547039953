<template>
    <el-drawer ref="drawer" :with-header="false" size="30%" :before-close="handleClose" :visible.sync="dialogFormVisible"
        direction="rtl" custom-class="demo-drawer">
        <div class="demo-drawer__content">
            <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px"
                style="width: 100%; padding: 10px; height: 100vh; overflow-y: scroll">
                <el-tabs tab-position="top" style="height: 200px">
                    <el-tab-pane tab-index="-1" label="基本信息">
                        <el-form-item label="标签名称" prop="name">
                            <el-input v-model="temp.name" clearable />
                        </el-form-item>

                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div class="demo-drawer__footer" style="position: fixed; top: 15px; right: 30px">
                <el-button size="mini" @click="$refs.drawer.closeDrawer()">取 消</el-button>
                <el-button size="mini" :loading="btnLoading" type="primary" @click="saveData()">
                    保存
                </el-button>
            </div>
        </div>
    </el-drawer>
</template>
  
<script>
import { labelAdd } from "@/api/label";

export default {
    name: "agentForm",
    components: {},
    props: ["options"],
    data() {
        return {
            btnLoading: false,
            temp: {
                id: 0,
                name: "",
                group_id:0,
            },
            dialogFormVisible: false,
            rules: {
                name: [
                    { required: true, message: "标签名称必填", trigger: "blur" },
                ],
            },
        };
    },
    watch: {
        dialogFormVisible: {
            handler() {
                this.resetTemp();
            },
            deep: true,
            sync: true,
        }
        // temp: {
        //     handler(newVal, oldVal) { },
        //     immediate: true,
        //     deep: true,
        // },
    },
    created() {
    },
    destroyed() { },
    methods: {
        handleClose(done) {
            if (this.btnLoading) {
                return;
            }
            this.$confirm("更改将不会被保存，确定要取消吗？")
                .then((_) => {
                    this.dialogFormVisible = false;
                })
                .catch((_) => { });
        },

        resetTemp() {
            this.temp = {
                id: 0,
                name: "",
            };
        },
        handleCreate(group_id) {
            this.btnLoading = false;
            this.dialogFormVisible = true;
            this.temp.group_id = group_id;
            this.currentIndex = -1;
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        handleUpdate(data) {
            this.btnLoading = false;
            this.dialogFormVisible = true;
            this.temp.id = data.id;
            this.temp.name = data.name;
            this.temp.group_id = data.group_id;
            this.$nextTick(() => {
                this.$refs["dataForm"].clearValidate();
            });
        },
        saveData() {
            this.btnLoading = true;
            this.$refs["dataForm"].validate((valid) => {
                if (valid) {
                    const _this = this;
                    labelAdd(this.temp)
                        .then((response) => {
                            if (response.status === 1) {
                                if (!_this.temp.id) {
                                    _this.temp.id = response.data.id;
                                }
                                this.$emit("requestLabelList");
                                _this.dialogFormVisible = false;
                                _this.$message.success(response.msg);
                            }
                            _this.btnLoading = false;
                        })
                        .catch((error) => {
                            this.btnLoading = false;
                        });
                } else {
                    this.btnLoading = false;
                }
            });
        },
    },
};
</script>
  