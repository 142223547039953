<template>
  <div class="app-container">
    <!-- 搜索 -->
    <div class="filter-container">
      <el-form :inline="true" :model="listQuery" class="form-inline">
        <div>
          <el-form-item label="">
            <el-input v-model="listQuery.username" placeholder="搜索用户名称" clearable size="small" />
          </el-form-item>
          <el-form-item label="">
            <el-select size="small" multiple collapse-tags v-model="listQuery.company_id" placeholder="租户名称">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="">
            <el-select v-model="listQuery.province" placeholder="所在省份">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item> -->
        </div>

        <div>
          <el-form-item>
            <el-button plain v-waves icon="el-icon-refresh" size="small" @click="handleFilterClear">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-waves type="primary" icon="el-icon-search" size="small" @click="handleFilter">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-waves type="primary" icon="el-icon-plus" size="small"
              @click="$refs.agentForm.handleCreate()">新建用户</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <!-- 表格 -->
    <el-table :key="tableKey" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%">
      <el-table-column label="用户名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="邮箱" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_enabled" @change="value => { statusSwitch(value, scope.row.id) }"
            active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" />
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="负责租户" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.company_list.length != 0">
            <div v-for="item in scope.row.company_list" :key="item.id">
              <!-- <el-table-column>{{ item.name + '-' }} {{ item.status === 1 ? "正常" : item.status === 2 ? "过期" : "禁用" }}</el-table-column> -->
              <el-row :class="item.status === 2 ? 'table_renter_name_red' : '' " >{{ item.name + '-' }} {{ item.status === 1 ? "正常" : item.status === 2 ? "过期" : "禁用" }} </el-row>
            </div>
          </div>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column label="租户所在地" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.company_list.length != 0">
            <div v-for="(item, index) in scope.row.region_list" :key="index">
              <el-row v-if="item">{{ JSON.parse(item)[0].name }}</el-row>
              <el-row v-else>无</el-row>
            </div>
          </div>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="$refs.agentForm.handleUpdate(scope.row)" type="text">编辑</el-button>
          <el-popconfirm style="margin-left: 10px;" confirm-button-text="确定" cancel-button-text="取消" icon="el-icon-info"
            icon-color="red" title="是否确定删除该用户吗？" @confirm="handleDeleteData(scope.row.id)">
            <el-button slot="reference" type="text"> <span style="color: #F56C6C;">删除</span> </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination v-show="total > 0" :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.psize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>


    <agentForm ref="agentForm" @requestAgentList="requestAgentList" :options="options"></agentForm>
  </div>
</template>
  
<script>
import { agentList, accountAdd } from "@/api/user";
import { companySelectList } from "@/api/renter";
import waves from "@/directive/waves";
import agentForm from "./components/addForm"

export default {
  name: "Log",
  components: { agentForm },
  directives: {
    waves,
  },
  filters: {
  },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        psize: 10,
        username: "",
        province: null,
        company_id: [],
      },
      options: [],
    };
  },
  watch: {},
  created() {
    this.requestCompanySelectList()
    this.requestAgentList();
  },
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.requestAgentList();
    },
    handleFilterClear() {
      this.listQuery = {
        page: 1,
        psize: 10,
        username: "",
        province: null,
        company_id: [],
      };
      this.requestAgentList();
    },
    handleSizeChange(val) {
      this.listQuery.psize = val;
      this.requestAgentList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.requestAgentList();
    },
    requestAgentList() {
      this.listLoading = true;
      let request_data = {...this.listQuery};
      request_data.company_id = request_data.company_id.toString()
      agentList(request_data).then((res) => {
        this.list = res.data.list;
        this.total = res.data.total;
        this.listLoading = false;
      });
    },
    //关闭添加编辑抽屉
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    //删除数据
    handleDeleteData(id) {
      let requestData = { id, is_del: 1 }
      accountAdd(requestData)
        .then(res => {
          if (res.status === 1) {
            this.$message.success(res.msg)
            this.requestAgentList();
          } else {
            this.$message.error(res.msg)
          }
        })
    },

    requestCompanySelectList() {
      companySelectList()
        .then(res => {
          this.options = res.data.list
        })
    },
    // 状态开关
    statusSwitch(value, id) {
      accountAdd({ is_enabled: value, id })
        .then(res => {
          if (res.status === 1) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
.form-inline {
  display: flex;
  justify-content: space-between;
}
.table_renter_name_red{
  color: rgb(245, 108, 108);
}
</style>